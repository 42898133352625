












































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingPayment from './AccountSettingPayment.vue'
import AccountSettingOrders from './AccountSettingOrders.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import { UserModule } from '@/store/modules/userModule'
import { Action } from '@/store/decorators'
import { LoginPayload } from '@/models/Auth'
import { User } from '@/models/User'
import axios from 'axios'
import {
  defaultParamsPayload,
  ModelPayload,
  ParamsPayload
} from '@/models/Payloads'

import { defaultPagination, Pagination } from '@/models/General'
@Component({
  name: 'AdminServicesList',
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingOrders,
    AccountSettingPayment,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification
  }
})
export default class AdminServicesList extends Vue {
  @Action(UserModule, 'getUserInformation') private getUserInformation!: (payload: LoginPayload) => Promise<User>

  private options: Object = {}
  private userData: Object = {}
  private created () {
    this.fetchUserInformation()

    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  }

  private async fetchUserInformation () {
    let payload = {
      email: 'superadmin@washir.com',
      password: '12345678',
      deviceId: '123456',
      deviceOs: 'web',
      deviceToken: 'some token',
      appVersion: '0.1'
    }
    let param = await this.getUserInformation(payload)
    this.userData = param
  }
}
